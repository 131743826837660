<template>
  <el-input
    :size="size"
    :placeholder="placeholder"
    :style="{ width: width }"
    v-model.trim="inputValue"
    @keyup.native.enter="handleSearch"
  >
    <i slot="suffix" class="el-input__icon iconfont icon-icon_sousuo" @click="handleSearch"></i>
  </el-input>
</template>

<script>
import { Input } from 'element-ui';

export default {
  components: {
    [Input.name]: Input,
  },

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: String,
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: String,
      default: '280px',
    },
  },

  data() {
    return {
      inputValue: '',
    };
  },

  computed: {
    // 支持回显
    searchData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    handleSearch() {
      this.$emit('input', this.inputValue);
      this.$emit('search', this.inputValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.iconfont {
  color: #8b8f8f;
  cursor: pointer;
}

::v-deep .el-input__suffix {
  right: 13px;
}
</style>

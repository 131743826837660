// components
import { Button, Table, TableColumn, Dialog, Input, Select, Option, Form, FormItem } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
// api
import {
  getAnhuiSchoolProjectPage,
  getAnhuiSchoolByName,
  getAnhuiSchoolByProjectCode,
  saveAnhuiSchoolProject,
} from '@/api/back-stage';

export default {
  name: 'allPassSingleSign',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    backStageHeader,
    searchInput,
    qlPagination,
  },
  data() {
    return {
      searchName: '',

      // 表格
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },

      // 表单
      visible: false,
      activeIndex: 0, // 关联表单-当前焦点学校项序号
      form: {
        id: '',
        schools: [
          {
            schoolId: '',
            list: [],
          },
        ],
        projectName: '',
        projectCode: '',
      },
    };
  },
  methods: {
    // 根据名称搜索
    searchData() {
      this.getDataList(true);
    },

    // 加载表格数据
    getDataList(reset = false) {
      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;
      getAnhuiSchoolProjectPage({
        pageIndex,
        pageSize,
        keyword: this.searchName,
      }).then((res) => {
        if (res.result) {
          this.listData.list = res.result.data;
          this.listData.total = res.result.recordCount;
        } else {
          this.listData.list = [];
          this.listData.total = 0;
        }
      });
    },

    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },

    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },

    // 新增关联
    handleAdd() {
      this.visible = true;
    },

    // 编辑关联
    handleEdit(row) {
      this.visible = true;
      let { id, projectName, projectCode } = row;
      this.form = { id, projectName, projectCode };
      // 默认已选学校
      this.getSchoolProjectDetail(projectCode);
    },

    // 获取学校项目详情
    getSchoolProjectDetail(projectCode) {
      getAnhuiSchoolByProjectCode({ projectCode }).then((res) => {
        const defaultSchools = res.result.map((s) => {
          let { schoolId, schoolName } = s;
          return {
            schoolId,
            list: new Array({ schoolId, schoolName }),
          };
        });
        this.$set(this.form, 'schools', defaultSchools);
      });
    },

    // 远程搜索注册学校
    querySearchAsync(query) {
      if (query !== '') {
        getAnhuiSchoolByName({
          schoolName: query,
        }).then((res) => {
          const selectedSchoolIds = this.form.schools.map((s) => s.schoolId);
          this.form.schools[this.activeIndex].list = res.result
            .filter((s) => !selectedSchoolIds.includes(s.id))
            .map((s) => {
              return {
                schoolId: s.id,
                schoolName: s.name,
              };
            });
        });
      } else {
        this.form.schools[this.activeIndex].list = [];
      }
    },

    // 添加关联学校项
    addSchoolItem() {
      this.form.schools.push({
        schoolId: '',
        list: [],
      });
    },

    // 删除关联学校项
    removeSchoolItem(index) {
      this.form.schools.splice(index, 1);
    },

    // 保存
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let { id, schools, ...form } = this.form;

          // 已选学校
          const selectSchools = schools.map((s) => {
            let schoolName = s.list.find((sc) => sc.schoolId === s.schoolId).schoolName;
            return {
              schoolId: s.schoolId,
              schoolName,
            };
          });

          let params = {
            ...form,
            schoolArrStr: JSON.stringify(selectSchools),
          };
          id && (params.id = id);

          saveAnhuiSchoolProject(params).then(() => {
            this.$message.success('保存成功！');
            this.visible = false;
            this.getDataList();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 表单重置
    resetForm() {
      this.form = {
        id: '',
        schools: [
          {
            schoolId: '',
            list: [],
          },
        ],
        projectName: '',
        platformUrl: '',
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getDataList();
  },
};
